<template>
  <!-- 查看商品列表弹窗组件 -->
  <el-dialog :visible="showDialog" @close="close" width="800px" title="设置限购条件">
    
    <el-form ref="goodActiveForm" :model="lrGoodsActivityCondition" label-width="0px" class="editForm"  :inline="true">
      <el-form-item prop="restrictionConditions">
        <el-radio-group v-model="lrGoodsActivityCondition.restrictionConditions" @change="changeConditions">
            <el-radio :label="0">不限购</el-radio>
            <el-radio :label="1">每单限购N件</el-radio>
            <el-radio :label="2">每日限购N件</el-radio>
            <el-radio :label="3">活动期间限购N件</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 每单限购N件 -->
      <template v-if="lrGoodsActivityCondition.restrictionConditions===0">
        <el-form-item class="inputStyle" prop="fristRestrition">
            新用户首单限制
            <el-input-number v-model="lrGoodsActivityCondition.fristRestrition" controls-position="right" :min="0"></el-input-number>
            件享受首价，超出此数量，下单时商品恢复原价，若存在“活动价”则按“活动价”购买
        </el-form-item>

      </template>
      <!-- 每单限购N件 -->
      <template v-if="lrGoodsActivityCondition.restrictionConditions===1">
        <el-form-item class="inputStyle" prop="activityRestriction">
            每个订单限制
            <el-input-number v-model="lrGoodsActivityCondition.activityRestriction" controls-position="right" :min="0"></el-input-number>
            件享受活动价，超出此数量，下单时商品恢复原价
        </el-form-item>
        <el-form-item class="inputStyle"  prop="fristRestrition">
            新用户首单限制
            <el-input-number v-model="lrGoodsActivityCondition.fristRestrition"  controls-position="right" :min="0"></el-input-number>
            件享受首单价，超出此数量，下单时商品恢复原价
        </el-form-item>
      </template>
      <!-- 每日限购N件 -->
      <template v-if="lrGoodsActivityCondition.restrictionConditions===2">
        <el-form-item class="inputStyle" prop="activityRestriction">
            单个用户每日
            <el-input-number v-model="lrGoodsActivityCondition.activityRestriction"  controls-position="right" :min="0"></el-input-number>
            件享受活动价，单日超出此数量，下单时商品恢复原价
        </el-form-item>
        <el-form-item class="inputStyle"  prop="fristRestrition">
            新用户首单限制
            <el-input-number v-model="lrGoodsActivityCondition.fristRestrition" controls-position="right" :min="0"></el-input-number>
            件享受首单价，超出此数量，下单时商品恢复原价
        </el-form-item>
      </template>
      <!-- 活动期间限购N件 -->
      <template v-if="lrGoodsActivityCondition.restrictionConditions===3">
        <el-form-item class="inputStyle" prop="activityRestriction">
            单个用户在本活动期内限制
            <el-input-number v-model="lrGoodsActivityCondition.activityRestriction" controls-position="right" :min="0"></el-input-number>
            件享受活动价，超过此数量，下单时商品恢复原价
        </el-form-item>
        <el-form-item class="inputStyle"  prop="fristRestrition">
            新用户首单限制
            <el-input-number v-model="lrGoodsActivityCondition.fristRestrition" controls-position="right" :min="0"></el-input-number>
            件享受首单价，超出此数量，下单时商品恢复原价
        </el-form-item>
      </template>
      
    </el-form>
    <div class="flx-row ali-c js-c mt-50" >
      <el-button class="ml-15" size="medium" type="primary" @click="saveGoods">保存</el-button>
      <el-button plain size="medium" @click="cancelSelect">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  mounted() {
   
  },
  data() {
    return {
      showDialog:false,
      idx:'',
      isSetAll:false,//判断是否设置所有
      lrGoodsActivityCondition:{
          restrictionConditions:0,
          activityRestriction:undefined,
          fristRestrition:1
      }
    }
  },
  methods: {
    close() {
      this.showDialog = false;
    },
    cancelSelect() {
      this.showDialog = false;
    },
    saveGoods(){
        if(this.isSetAll){
            this.$confirm('批量设置限购条件会覆盖原有单个商品的限购条件，是否确定进行批量设置?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$message({
                type: 'success',
                message: '批量设置完成!'
              });
              this.$emit('getAllData',this.lrGoodsActivityCondition)
              this.showDialog = false
              this.isSetAll = false
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消批量设置'
              });
              this.showDialog = false;   
              this.isSetAll = false       
            });
            
        }else{
          let obj = {
              index:this.idx,
              data:this.lrGoodsActivityCondition
          }
          this.$emit('getData',obj)
          this.showDialog = false
        }
        
        
    },
    // 设置表单的值
    setData(data,idx){
      this.idx = idx
      let  obj = JSON.parse(JSON.stringify(data))
      this.lrGoodsActivityCondition = {
        restrictionConditions:obj.restrictionConditions?obj.restrictionConditions:0,
        activityRestriction:obj.activityRestriction?obj.activityRestriction:undefined,
        fristRestrition:obj.fristRestrition?obj.fristRestrition:0
      }
      
    },
    // 重置表单
    reset(){
      this.lrGoodsActivityCondition = {
          restrictionConditions:0,
          activityRestriction:undefined,
          fristRestrition:0,
        }
    },
    changeConditions(){
        this.lrGoodsActivityCondition.activityRestriction = undefined
        this.lrGoodsActivityCondition.fristRestrition = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-inline{
    display: flex;
    justify-content: space-between;
    /deep/.el-form-item{
      width:50%;
    }
  }
  .editForm{
    width:95%;
    margin:0 auto;
  }
  .inputStyle{
      /deep/ .el-input-number{width:120px;margin:0 10px;}
  }
</style>