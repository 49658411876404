<template>
  <!-- 查看商品列表弹窗组件 -->
  <el-dialog :visible="showGoodsDetail" @close="close" width="800px" title="编辑商品信息">
    <el-form ref="goodActiveForm" :model="form" label-width="130px" class="editForm" >
      <el-form-item label="商品条形码:" prop="goodsCode">
        <el-input disabled v-model="form.goodsCode"></el-input>
      </el-form-item>
      <el-form-item label="商品名称:" prop="goodsName">
        <el-input  disabled v-model="form.goodsName"></el-input>
      </el-form-item>
      <el-form-item label="一级类目:" prop="classifyName">
        <el-input disabled v-model="form.classifyName"></el-input>
      </el-form-item>
      <el-form-item label="二级类目:" prop="secondClassifyName">
        <el-input disabled v-model="form.secondClassifyName"></el-input>
      </el-form-item>
      <div class="form-inline">
        <el-form-item label="实价(元):" prop="actualPrice">
          <el-input disabled v-model="form.actualPrice"></el-input>
        </el-form-item>
        <el-form-item label="库存:" prop="stock">
          <el-input disabled v-model="form.stock"></el-input>
        </el-form-item>
      </div>
      <div class="form-inline">
        <el-form-item label="原价(元):" prop="originalPrice">
          <el-input disabled v-model="form.originalPrice"></el-input>
        </el-form-item>
        <el-form-item label="活动库存:">
          <el-input-number v-model="form.activityStock" controls-position="right" :min="0" :max="parseInt(form.stock)" :precision="0"></el-input-number>
        </el-form-item>
      </div>
      <div class="form-inline">
        <el-form-item label="活动价(元):" >
          <el-input-number v-model="form.activityPrice" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="新用户首单价(元):">
           <el-input-number v-model="form.firstPrice" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>
      </div>
      <div class="form-inline">
        <el-form-item label="商品减运(元):">
          <el-input-number v-model="form.freightPrice" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>
      </div>
    </el-form>
    <div class="flx-row ali-c js-c mt-50" >
      <el-button class="ml-15" size="medium" type="primary" @click="saveGoods">保存</el-button>
      <el-button plain size="medium" @click="cancelSelect">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    goodsDetail:Object
  },
  mounted() {
    
  },
  data() {
    return {
      showGoodsDetail:false,
      form:{},
      idx:''
    }
  },
  methods: {
    
    close() {
      this.showGoodsDetail = false;
    },
    cancelSelect() {
      this.showGoodsDetail = false;
    },
    saveGoods(){
      let obj = {
        index:this.idx,
        data:this.form
      }
      this.$emit('getData',obj)
      this.showGoodsDetail = false;
    },
    // 设置表单的值
    setData(data,idx){
      this.idx = idx
      data = JSON.parse(JSON.stringify(data))
      if(!data.lrGoodsActivityPrice){
        data.lrGoodsActivityPrice = {
          activityPrice:undefined,
          firstPrice:undefined,
          activityStock:data.stock,
          freightPrice:0.00
        }
      }
      data.activityPrice?data.activityPrice:data.activityPrice=undefined
      data.firstPrice?data.firstPrice:data.firstPrice=undefined
      data.activityStock?data.activityStock:data.activityStock=data.stock
      data.freightPrice?data.freightPrice:data.freightPrice=0.00
      this.form = JSON.parse(JSON.stringify(data))
    },
    // 重置表单
    reset(){
      this.$nextTick(() => {
        if(this.showGoodsDetail){
          this.$refs['goodActiveForm'].resetFields()
        }
        
      });
      
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-inline{
    display: flex;
    justify-content: space-between;
    /deep/.el-form-item{
      width:50%;
    }
  }
  .editForm{
    width:95%;
    margin:0 auto;
  }
</style>