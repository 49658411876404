<template>
  <el-dialog
    title="选择互斥活动"
    :visible.sync="showMutexActive"
    width="800px"
    top="10vh"
    :close-on-click-modal="false"
  >
    <div class="flx-row ali-c js-btw mb-20">
      <div>
        <el-input
          placeholder="请输入活动名称"
          class="form-input-200 mr-10"
          v-model="searchForm.activityName"
        ></el-input>
        <el-select v-model="searchForm.status" placeholder="请选择活动状态">
          <el-option label="全部" value=""></el-option>
          <el-option label="上架" value="1"></el-option>
          <el-option label="下架" value="0"></el-option>
        </el-select>
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          class="ml-15"
          @click="initData"
          >查询</el-button
        >
        <el-button
          size="small"
          icon="el-icon-refresh"
          class="ml-15"
          @click="refresh"
          >刷新</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="300px"
      ref="mutexActiveRef"
      @select="changeSelect"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="activityName" label="活动名称"> </el-table-column>
      <el-table-column prop="storeName" label="所属门店"> </el-table-column>
      <el-table-column prop="activityTime" label="活动时间"> </el-table-column>
      <el-table-column prop="typeName" label="活动形式"> </el-table-column>
      <el-table-column prop="statusName" label="上架状态"> </el-table-column>
    </el-table>
    <Pagination
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      :pageSizes="pageSizes"
      @syncPageData="syncPageData"
    />
    <div slot="footer" class="dialog-footer">
      <el-button @click="showMutexActive = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getMutrx } from "@/api/market/activity";
export default {
  props: {
    storeId: String,
  },
  components: {
    Pagination,
  },
  watch:{
    showMutexActive(val,oVal){
        this.setCurrActive()
    },
  },
  data() {
    return {
      showMutexActive: false,
      tableData: [],
      searchForm: {
        status: "",
        activityName: "",
        storeId:"",
        actId:this.$route.query.id
      },
      selectActive:[],//选择的活动
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created(){
    this.searchForm.storeId = this.$route.query.storeId
  },
  mounted() {
    this.initData(1);

  },
  methods: {
    async initData(type) {
      if (type == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
      }
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId:this.storeId,
        ...this.searchForm
      };
      let res = await getMutrx(data);
      if (res.data.code == 200) {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      }
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
    refresh() {
      this.searchForm.status = "";
      this.searchForm.activityName = "";
      this.initData();
    },
    submit() {
      this.showMutexActive = false;
      this.$emit('getMutexActive',this.selectActive)
    },
    changeSelect(select,row){ //选择活动
      // this.selectActive = data
      // 判断总勾选列表中是否含有点击项
      let includeFlag = this.selectActive.some((item, index) => {
        return item.id == row.id;
      });
      if (includeFlag) {
        // 有-删除
        let index = this.selectActive.findIndex(
          item => item.id === row.id
        );
        this.selectActive.splice(index, 1);
      } else {
        // 无-添加
        this.selectActive.push(row);
      }
    },
    //表格中是否存在已选择的活动
    setCurrActive(){
      this.$nextTick(() => {
        let mutexActiveIdList = this.selectActive.map(item => item.id);
        let tableDataId = this.tableData.map(item => item.id);
        this.$refs.mutexActiveRef.clearSelection();
        let selectArr = [];
        for(let i = 0;i<tableDataId.length;i++){
          if(mutexActiveIdList.indexOf(tableDataId[i])!=-1){
            selectArr.push(this.tableData[i])
            this.$refs.mutexActiveRef.toggleRowSelection(this.tableData[i]);
          }
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
  
/deep/.el-table__header-wrapper  .el-checkbox{
	display:none
}
</style>