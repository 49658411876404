<template>
    <el-dialog :visible="showDialog" @close="close" width="70%" title="活动商品冲突明细">
        <el-alert
            title="提示：以下商品存在其它并行的优惠限购活动，须处理冲突后才能成功创建活动。"
            type="error"
            :closable="false"
            >
        </el-alert>
        <el-radio-group v-model="newOrOld" @change="changeActive" class="act_radio_group">
          <el-radio :label="0">覆盖新活动</el-radio>
          <el-radio :label="1">保留原活动</el-radio>
        </el-radio-group>
        <!-- 导入活动商品提交后出现的活动商品冲突 -->
        <div class="table_box" v-if="showDialog">
            <el-table
                    class="mt-24 member-table"
                    :data="conflictTableData()"
                    :span-method="objectSpanMethod"
                    width="100%"
                    height="400px"
                    ref="goodsTable"
                    stripe
                  >
                  <el-table-column label="活动名称" min-width="180"  fixed="left">
                      <template slot-scope="scope">
                        <div v-if="scope.$index%2==0" class="blueColor">
                          {{formData.activityName}}(新)
                        </div>
                        <div v-else class="redColor">
                          {{scope.row.activityName}}(原)
                        </div>
                      </template>
                    </el-table-column>
                   <el-table-column label="活动时间" min-width="180">
                      <template slot-scope="scope">
                        <div v-if="scope.$index%2==0" class="blueColor">
                          <br>{{formData.startTime}} 至  <br>{{formData.endTime}}
                        </div>
                        <div v-else class="redColor">
                          <br>{{scope.row.startTime}} 至 <br>{{scope.row.endTime}}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品条形码" min-width="100">
                      <template slot-scope="scope">
                        <span>{{scope.row.goodsCode}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品名称" align="center"  min-width="200">
                      <template slot-scope="scope">
                        <span>{{scope.row.goodsName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="一级类目" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.classifyName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="二级类目" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.secondClassifyName}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="实价" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.actualPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="原价" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.originalPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="库存"  align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.stock}}</span>
                      </template>
                    </el-table-column>
                    <!-- new table-->
                    <el-table-column label="活动价(元)" align="center" min-width="90">
                      <template slot-scope="scope">
                        <span>{{scope.row.activityPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="活动库存" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.activityStock}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="新用户首单价(元)" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.firstPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品减运(元)" align="center">
                      <template slot-scope="scope">
                        <span>{{scope.row.freightPrice}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="限购条件" min-width="200">
                      <template slot-scope="scope">
                          <span v-if="scope.row.restrictionConditions===0">
                            不限购
                            新用户首单限购{{scope.row.fristRestrition}}件
                          </span>
                          <span v-if="scope.row.restrictionConditions===1">
                            每单限购{{scope.row.activityRestriction}}件,<br>
                            新用户首单限购{{scope.row.fristRestrition}}件
                          </span>
                          <span v-if="scope.row.restrictionConditions===2">
                            每日限购{{scope.row.activityRestriction}}件,<br>
                            新用户首单限购{{scope.row.fristRestrition}}件
                          </span>
                          <span v-if="scope.row.restrictionConditions===3">不限购
                            活动期限购{{scope.row.activityRestriction}}件,<br>
                            新用户首单限购{{scope.row.fristRestrition}}件
                          </span>
                      </template>
                    </el-table-column>
                    <!-- new table end -->
                    <el-table-column label="操作"  fixed="right" width="160">
                      <template slot-scope="scope">
                        <div class="pdlr-20">
                            <el-radio v-model="condition[parseInt(scope.$index/2)]" :label="scope.$index">覆盖原活动</el-radio>
                            <el-radio v-model="condition[parseInt(scope.$index/2)]" :label="scope.$index+1">保留原活动</el-radio>
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
            <Pagination
                :total="total"
                :pageNum="pageNum"
                :pageSize="pageSize"
                :pageSizes="pageSizes"
                @syncPageData="syncPageData"
              />
        </div>
        <div class="flx-row ali-c js-c mt-50" >
            <el-button class="ml-15" size="medium" type="primary" @click="submit">提交</el-button>
            <el-button plain size="medium" @click="cancelSelect">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import Pagination from "@/components/Pagination"
export default {
    components: {
      Pagination
    },
    data(){
        return {
            newOrOld:0,
            showDialog:false,
            conflicData:[],
            newData:[],//处理后的数据
            condition:[],//冲突选择菜单的列表
            formData:{},
            total:0,
            pageNum: 1,
            pageSize: 10,
            pageSizes: [10, 20, 40, 60],
            goodsTableData:[],
        }
    },
    methods:{
        close() {
            this.showDialog = false;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 14) {
                if (rowIndex % 2 === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
        getData(data){
            this.condition=[]
            this.conflicData = data
            this.total = this.conflicData[1].length
            let arr = this.conflicData[1]
            for(let i=0;i<arr.length;i++){
                if(i%2===0){
                    this.condition.push(i)
                    // 提交的时候 限购条件放在lrGoodsActivityCondition对象里面，解决冲突的时候需要提取出来
                    arr[i].restrictionConditions=arr[i].lrGoodsActivityCondition.restrictionConditions,
                    arr[i].activityRestriction=arr[i].lrGoodsActivityCondition.activityRestriction,
                    arr[i].fristRestrition=arr[i].lrGoodsActivityCondition.fristRestrition
                }
            }
        },
        setFormData(data){
          this.formData = data
        },
        // changeData(data){
        //     console.log(data,this.condition)
        // },
        cancelSelect() {
            this.showDialog = false;
        },
        submit(){
            let arr = [];//选择的新活动
            for(let i =0;i<this.condition.length;i++){
                if(this.condition[i]%2===0){ //判断为新的活动,则添加进入数据
                    let obj = this.conflicData[1][this.condition[i]]
                    obj.complete = true //处理后的商品需要设置为true
                    arr.push(obj);
                }
            }
            this.newData = this.conflicData[0].concat(arr);
            this.newData = this.newData.map(item=>{
                item.lrGoodsActivityPrice = {
                    activityPrice:item.activityPrice,
                    firstPrice:item.firstPrice,
                    activityStock:item.activityStock,
                    freightPrice:item.freightPrice
                }
                return item
            })
            this.showDialog = false
            this.$emit('getConflictResult',this.newData)
        },
        changeActive(val){
          let cdlen = this.condition.length;
          let arr = [];
          if(val==0){
            for(let i=0;i<cdlen;i++){
              arr.push(2*i)
            }
          }else{
            for(let i=0;i<cdlen;i++){
              arr.push(2*i+1)
            }
          }
          this.condition = arr
        },
        syncPageData(data) { //导入商品的分页
          this.pageNum = data.num;
          this.pageSize = data.size;
        },
        conflictTableData(){
            let i = this.pageNum
            let s = this.pageSize
            return this.conflicData[1].slice((i-1)*s,i*s)
        }
            
    }
}
</script>

<style lang="scss" scoped>
    .act_radio_group{
      float:right;
      margin:10px;
    }
    .blueColor{
      color:#409EFF;
    }
    .redColor{
      color:#F56C6C;
    }
</style>